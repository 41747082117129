<template>
  <div class="content-wrapper">
    <div class="payroll main-content">

      <div class="page-header">
        <h1>Addition Payrolls</h1>
      </div>

      <div class="view-toolbars">
        <ItemFilter class="fitler" placeholder="Enter Pay Item Name" @search="searchPayroll" @reset="resetFilter">
          <el-form class="search-form" :model="searchForm">
            <div class="row">
              <el-form-item label="Staff ID">
                <el-input v-model="searchForm.staff_id"></el-input>
              </el-form-item>
              <el-form-item label="Staff Name">
                <el-input v-model="searchForm.employee_name"></el-input>
              </el-form-item>
              <el-form-item label="Position">
                <el-input v-model="searchForm.position"></el-input>
              </el-form-item>
              <el-form-item label="Department">
                <el-input v-model="searchForm.department"></el-input>
              </el-form-item>
            </div>
          </el-form>
        </ItemFilter>
        <div class="tool">
          <router-link :to="{name:'AddAdditionPayroll'}">
            <button class="btn btn-primary">
              <i class="el-icon-plus"></i>
              New
            </button>
          </router-link>
        </div>
      </div>

      <div class="payroll-list">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Staff ID</th>
              <th scope="col">Name</th>
              <th scope="col">Position</th>
              <th scope="col">Department</th>
              <th scope="col">Pay Item</th>
              <th scope="col">Amount</th>
              <th scope="col">Date</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="payroll in payrolls">
              <th scope="row">{{payroll.staff_id}}</th>
              <td>{{payroll.employee_name}}</td>
              <td>{{payroll.position}}</td>
              <td>{{payroll.department}}</td>
              <td>{{payroll.pay_item}}</td>
              <td>${{payroll.amount}}</td>
              <td>{{formatDateTime(payroll.create_date, 'YYYY-MM-DD HH:mm:ss')}}</td>
              <td class="action">
                <router-link :to="{name:'AdditionPayroll', params: {id: payroll.id}}">View</router-link>
                <a href="#" class="delete-link" @click="deletePayroll(payroll.id)">Delete</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ItemFilter from '@/components/ItemFilter.vue';
import Common from '@/lib/common';
import Financial from '@/lib/financial';

export default {
  name: 'AdditionPayrolls',
  components: {
    ItemFilter,
  },
  data(){
    return {
      searchForm: {
        staff_id: '',
        employee_name: '',
        position: '',
        department: '',
      },
      originPayrolls: [],
      payrolls: [],
    }
  },
  mounted(){
    this.loadPayrolls();
  },
  methods:{
    async loadPayrolls(){
      const loginInfo = Common.getLoginInfo();
      try{
        const payrolls = await Financial.loadAllAdditionPayrolls(this.apiUrl, loginInfo);
        this.originPayrolls = payrolls;
        this.payrolls = payrolls;
      }catch(err){
        console.log(err);
      }
    },
    resetFilter(){
      //Clean Filter
      for(let i = 0; i < Object.keys(this.searchForm).length; i++){
        const searchKey = Object.keys(this.searchForm)[i];
        this.searchForm[searchKey] = '';
      }
      this.payrolls = this.originPayrolls;
    },
    searchPayroll(textSearchValue){
      this.searchForm['pay_item'] = textSearchValue;
      const searchFilter = this.searchForm;
      const newPayrolls = Common.filterItems(searchFilter, this.originPayrolls);
      this.payrolls = newPayrolls;
    },
    formatDateTime(timestamp, format){
      const date = Common.formatDateTime(timestamp, format);
      return date;
    },
    async deletePayroll(payrollId){
      const loginInfo = Common.getLoginInfo();
      try{
        await Financial.deleteAdditionPayroll(this.apiUrl, payrollId, loginInfo);
        const newPayrolls = this.payrolls.filter(function(item) {
          return item.id !== payrollId;
        });
        this.payrolls = newPayrolls;
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style lang="scss">
.view-toolbars{
  display: flex;
  position: relative;
  .fitler{
    flex: 1;
  }
  .el-icon-plus{
    font-size: 1.2em;
  }
}
.payroll-list{
  .action{
    a{
      display: inline-block;
      margin-right: 10px;
    }
    .delete-link{
      color: red;
    }
  }
}
</style>
